var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('module',{attrs:{"enable-flex":""}},[_c('m-search',{ref:"search",on:{"search":_vm.searchHandle}},[_c('m-search-item',{attrs:{"label":"报工编号"}},[_c('c-input',{attrs:{"name":"work_order_number"}})],1),_c('m-search-item',{attrs:{"label":"生产任务编号"}},[_c('c-input',{attrs:{"name":"task_number"}})],1),_c('m-search-item',{attrs:{"label":"质检状态"}},[_c('c-checkbox-group',{attrs:{"name":"state","options":_vm.stateOptions}})],1),_c('m-search-item',{attrs:{"label":"报工时间","colspan":"2"}},[_c('c-datetime',{attrs:{"start-name":"start_date","end-name":"end_date","range":""}})],1),_c('m-search-item',{attrs:{"label":"报工人"}},[_c('c-input',{attrs:{"name":"create_user_name"}})],1)],1),_c('c-table',{ref:"table",attrs:{"height":"grow"}},[_c('c-table-column',{attrs:{"label":"报工编号","width":"100","name":"work_order_number","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"任务编号","width":"100","name":"task_number","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"质检状态","width":"80","name":"state","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == 1)?_c('span',{staticStyle:{"color":"blue"}},[_vm._v("待质检")]):_vm._e(),(data.state == 2)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("已质检")]):_vm._e(),(data.state == 3)?_c('span',{staticStyle:{"color":"red"},attrs:{"title":data.reject_remark}},[_vm._v("已驳回")]):_vm._e()]}}])}),_c('c-table-column',{attrs:{"label":"产品","width":"200","name":"product_name","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('span',[_vm._v(_vm._s(data.product_name))]),(data.product_code)?_c('span',[_vm._v("【"+_vm._s(data.product_code)+"】")]):_vm._e()]}}])}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"颜色","width":"80","name":"product_color","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}}}):_vm._e(),_c('c-table-column',{attrs:{"label":"颜色","name":"product_spec","width":"60","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number && item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}}}),_vm._l((_vm.sizeConfig),function(size,i){return [(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":String(i+1),"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data[size])?_c('span',[_vm._v(_vm._s(data[size]))]):_c('span',[_vm._v("-")])]}}],null,true)}):_vm._e()]}),_c('c-table-column',{attrs:{"label":"报工数","width":"60","name":"product_count","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"合格数","width":"60","name":"qualified_count","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == 2)?_c('span',[_vm._v(_vm._s(data.qualified_count))]):_c('span',[_vm._v("--")])]}}])}),_c('c-table-column',{attrs:{"label":"不合格数","width":"70","name":"unqualified_count","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == 2)?_c('span',[_vm._v(_vm._s(data.unqualified_count))]):_c('span',[_vm._v("--")])]}}])}),_c('c-table-column',{attrs:{"label":"报工人","width":"100","name":"create_user_realname","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"报工时间","width":"140","name":"create_date","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"质检审批人","width":"100","name":"quality_approval_user_realname","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"label":"质检审批时间","width":"140","name":"quality_approval_date","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}}}),_c('c-table-column',{attrs:{"type":"button","label":"操作","width":"140","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == 1 && _vm.auth('b_production_task_work'))?_c('c-table-button',{on:{"click":function($event){return _vm.approvalWork(data)}}},[_vm._v("质检审批")]):_vm._e(),(data.state == 1 && _vm.auth('b_production_task_work'))?_c('c-table-button',{on:{"click":function($event){return _vm.rejectWork(data)}}},[_vm._v("驳回")]):_vm._e()]}}])})],2)],1),_c('c-dialog',{ref:"workTask",attrs:{"title":'报工单：'+this.work_order_number+' - 质检审批',"height":"600","width":"680"},on:{"resolve":_vm.submitApprovalWork}},[_c('c-detail',{attrs:{"column":"1"}}),_c('c-table',{ref:"productTable",attrs:{"paging":false}},[(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"颜色","width":"150","name":"product_color","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}}}):_vm._e(),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"颜色","width":"100","name":"product_spec","row-span":({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}}}):_vm._e(),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"尺码","width":"80","name":"product_size"}}):_vm._e(),_c('c-table-column',{attrs:{"label":"报工数","width":"70","name":"product_count"}}),_c('c-table-column',{attrs:{"label":"合格数","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('c-input',{attrs:{"type":"number","range":[0,data.product_count]},model:{value:(data.qualified_count),callback:function ($$v) {_vm.$set(data, "qualified_count", $$v)},expression:"data.qualified_count"}})]}}])}),_c('c-table-column',{attrs:{"label":"不合格数","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('c-input',{attrs:{"type":"number","readonly":"","disabled":""},model:{value:(data.product_count - data.qualified_count),callback:function ($$v) {_vm.$set(data.product_count - data, "qualified_count", $$v)},expression:"data.product_count - data.qualified_count"}})]}}])})],1)],1),_c('c-dialog',{ref:"rejectWorkTask",attrs:{"title":'报工单：'+this.work_order_number+' - 驳回',"width":"500"},on:{"resolve":_vm.submitRejectWork}},[_c('c-form',{ref:"rejectForm",attrs:{"unit-width":"100%"}},[_c('c-input',{attrs:{"type":"textarea","name":"reject_remark","maxlength":"1000","placeholder":"请输入驳回原因"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }