<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="报工编号">
					<c-input name="work_order_number"></c-input>
				</m-search-item>
				<m-search-item label="生产任务编号">
					<c-input name="task_number"></c-input>
				</m-search-item>
				<m-search-item label="质检状态">
					<c-checkbox-group name="state" :options="stateOptions"></c-checkbox-group>
				</m-search-item>
				<m-search-item label="报工时间" colspan="2">
					<c-datetime start-name="start_date" end-name="end_date" range></c-datetime>
				</m-search-item>
				<m-search-item label="报工人">
					<c-input name="create_user_name"></c-input>
				</m-search-item>
			</m-search>

			<c-table ref="table" height="grow">
				<c-table-column
					label="报工编号"
					width="100"
					name="work_order_number"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="任务编号"
					width="100"
					name="task_number"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="质检状态"
					width="80"
					name="state"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.state == 1" style="color:blue">待质检</span>
						<span v-if="data.state == 2" style="color:green">已质检</span>
						<span v-if="data.state == 3" style="color:red" :title="data.reject_remark">已驳回</span>
					</template>
				</c-table-column>
				<c-table-column
					label="产品"
					width="200"
					name="product_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>

				<c-table-column
					label="颜色"
					width="80"
					name="product_color"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="颜色"
					name="product_spec"
					width="60"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number && item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<template v-for="(size, i) in sizeConfig">
					<c-table-column
						:label="String(i+1)"
						width="50"
						v-if="systemConfig.system_size_enable=='ON'"
					>
						<template #default="{data}">
							<span v-if="data[size]">{{data[size]}}</span>
							<span v-else>-</span>
						</template>
					</c-table-column>
				</template>
				
				<c-table-column
					label="报工数"
					width="60"
					name="product_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="合格数"
					width="60"
					name="qualified_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template v-slot="{data}">
						<span v-if="data.state == 2">{{data.qualified_count}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="不合格数"
					width="70"
					name="unqualified_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template v-slot="{data}">
						<span v-if="data.state == 2">{{data.unqualified_count}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="报工人"
					width="100"
					name="create_user_realname"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="报工时间"
					width="140"
					name="create_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="质检审批人"
					width="100"
					name="quality_approval_user_realname"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="质检审批时间"
					width="140"
					name="quality_approval_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="140"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.work_order_number == data.work_order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 1 && auth('b_production_task_work')" @click="approvalWork(data)">质检审批</c-table-button>
						<c-table-button v-if="data.state == 1 && auth('b_production_task_work')" @click="rejectWork(data)">驳回</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--质检审批 弹框-->
		<c-dialog ref="workTask" :title="'报工单：'+this.work_order_number+' - 质检审批'" height="600" width="680" @resolve="submitApprovalWork">
			<c-detail column="1">
			    <!-- <c-detail-item label="产品名称">
			         {{this.product_name}}
			    </c-detail-item> -->
<!--			    <c-detail-item label="产品编码">
			        {{this.product_code}}
			    </c-detail-item>-->
			</c-detail>
			<c-table ref="productTable" :paging="false">
				<c-table-column
					label="颜色"
					width="150"
					name="product_color"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				></c-table-column>
				
				<c-table-column
					label="颜色"
					width="100"
					name="product_spec"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}"
				></c-table-column>
				
				<c-table-column
					label="尺码"
					width="80"
					name="product_size"
					v-if="systemConfig.system_size_enable=='ON'"
				></c-table-column>
				
				<c-table-column
					label="报工数"
					width="70"
					name="product_count"
				></c-table-column>
				
				<c-table-column
					label="合格数"
					width="80"
				>
					<template v-slot="{data}">
						<c-input v-model="data.qualified_count" type="number" :range="[0,data.product_count]"></c-input>
					</template>
				</c-table-column>
				
				<c-table-column
					label="不合格数"
					width="80"
				>
					<template v-slot="{data}">
						<c-input v-model="data.product_count - data.qualified_count" type="number" readonly disabled></c-input>
					</template>
				</c-table-column>
			</c-table>
		</c-dialog>
		
		<!--驳回 弹框-->
		<c-dialog ref="rejectWorkTask" :title="'报工单：'+this.work_order_number+' - 驳回'" width="500" @resolve="submitRejectWork">
			<c-form ref="rejectForm" unit-width="100%">
				<c-input type="textarea" name="reject_remark" maxlength="1000" placeholder="请输入驳回原因"></c-input>
			</c-form>
		</c-dialog>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_production_work',
	components: {
    
	},
	data() {
		return {
			work_order_number: '',
			product_name: '',
			product_code: '',
			editState: false,
			stateOptions: [{
				name: '待质检',
				value: 1
			}, {
				name: '已质检',
				value: 2
			}, {
				name: '已驳回',
				value: 3
			}]
		}
	},
	computed: {
		...mapState(['sizeConfig','systemConfig'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('workList.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/production/work/list',
				data
			});
		},
		//报工质检审批 - 弹框
		approvalWork(val) {
			this.work_order_number = val.work_order_number;
			this.work_id = val.id;
			this.order_number = val.order_number;
			this.product_name = val.product_name;
			this.product_code = val.product_code;
			this.$refs.productTable.load({
				url: '/production/work/product_list',
				data: {id:val.id},
				dataFormatter: data => {
					data.forEach(item => {
						item.qualified_count = item.product_count;
					});
					return data;
				}
			});
			this.$refs.workTask.open();
		},
		//报工质检审批 -确定
		submitApprovalWork(stop, next){
			stop();
			var productData = this.$refs.productTable.getRow();
			var productList = [];
			//合格数-小计
			var qualified_count = 0;
			//不合格数 - 小计
			var unqualified_count = 0;
			if(productData.length > 0){
				productData.forEach(item => {
					var item_data = item.data;
					productList.push({
						id: item_data.id,
						product_id: item_data.product_id,
						product_name: item_data.product_name,
						product_code: item_data.product_code,
						product_color: item_data.product_color,
						product_spec: item_data.product_spec,
						product_size: item_data.product_size,
						qualified_count: item_data.qualified_count||0,
						unqualified_count: item_data.product_count - item_data.qualified_count
						
					})
					qualified_count = parseFloat(qualified_count) + parseFloat(item_data.qualified_count||0);
					unqualified_count = parseFloat(unqualified_count) + parseFloat(item_data.product_count-(item_data.qualified_count||0));
				});
			}
			
			if(qualified_count == 0 && unqualified_count == 0){
				this.$message({
					type: 'warn',
					message: '请输入产品质检数'
				});
				return false;
			}
			
			this.request({
				url: '/production/work/approval',
				data: {
					id: this.work_id,
					work_order_number: this.work_order_number,
					order_number: this.order_number,
					qualified_count: qualified_count,
					unqualified_count: unqualified_count,
					product_list: productList
				},
				loading: true,
				success: () => {
					this.$refs.table.update();
					this.$message({
						type: 'success',
						message: '质检审批成功'
					});
					next();
				}
			});
			
		},
		//驳回 - 弹框
		rejectWork(val){
			this.work_order_number = val.work_order_number;
			this.work_id = val.id;
			this.$refs.rejectForm.clear();
			this.$refs.rejectWorkTask.open();
		},
		//驳回 -确定
		submitRejectWork(stop, next){
			stop();
			this.$refs.rejectForm.submit({
				url: '/production/work/reject',
				rule: {
					reject_remark: '请填写驳回原因'
				},
				dataFormatter: data => {
					data.id = this.work_id;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.rejectWorkTask.close();
				}
			});
		}
  }
}
</script>